<script setup lang="ts">
    useHead({
        bodyAttrs: {
            class: 'dark:bg-gray-950',
        },
    });
</script>

<template>
    <div class="min-h-screen py-14 flex items-center justify-center overlay">
        <slot></slot>
    </div>
</template>
